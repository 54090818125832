import React, { useState, useEffect } from "react";

// core components
//import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LeftNavbar from "../../components/Navbars/LeftNavbar.js";
import axios from "axios";
import { config } from '../../constant';
const getProjectUrl = config.url.API_URL+"/project/deleted-projects";
const getRevertUrl = config.url.API_URL+"/project/revert";

function TrashedDocumentsPage(props) {
	const [projects, setProjectData] = useState([]);
	const getProjectsWithAxios = async () => {
		const data1 = new FormData();
		data1.append("user_id", localStorage.getItem('token'));
		axios.post(getProjectUrl,data1)
		.then((response) => {
			console.log(response);
			const projects = response.data.projects;
			setProjectData(projects);				
		})
		.catch((error) => {
		// error response
		});
	};

	function handleRevert(project_id) {
		const data1 = new FormData();
		data1.append("id", project_id);
		axios.post(getRevertUrl,data1)
		.then((response) => {
			console.log(response);
			getProjectsWithAxios();				
		})
		.catch((error) => {
		// error response
		});
	};
	
	useEffect(() => {
		
		getProjectsWithAxios();
	}, []);	
	
	if(!localStorage.getItem('token')){
		window.location.href = process.env.PUBLIC_URL+"/login";
	}else{
	
	return (
		<div className="dashboard-main">
		<LeftNavbar />
		<div id="main">
            
            
			<div className="page-heading">
				<div className="row">
					<div className="col-12 col-md-9 order-md-1 order-last">
						<h3> Trashed Documents</h3>						
					</div>
					
				</div>
			</div>
			<div className="page-content">
				<section className="row">
					{ 
					projects && projects.map((project,index)=>( 
					<div className="col-sm-3" >
						
						<div className="home-graph-box box-new p-3 mb-4" style={{height:"150px"}}>
							<div className="row">
								<div className="col-md-8"><div className="box-icon-round"><i className="fa fa-file-text" aria-hidden="true"></i></div></div>
								<div className="col-md-4" style={{textAlign:"right"}} onClick={() => handleRevert(project.id)}><div className="box-icon-round" style={{height:"30px",width:"30px",cursor:"pointer"}}><i className="fa fa-undo" aria-hidden="true" style={{fontSize:"18px",lineHeight:"30px"}}></i></div></div>
							</div>
							<div className="box-title mt-3 mb-2"></div>
							<p>{project.title.toString().substring(0,50)}</p>
						</div>
						
					</div>
					))}					

					

				</section>
			</div>

            
        </div>
		</div>
	);
	}
}

export default TrashedDocumentsPage;