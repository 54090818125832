import React, { useState, useEffect } from "react";

// core components
//import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LeftNavbar from "../../components/Navbars/LeftNavbar.js";
import axios from "axios";
import { config } from '../../constant';
const getOutputUrl = config.url.API_URL+"/output/deleted-outputs";
const getRevertUrl = config.url.API_URL+"/output/revert";

function TrashedOutputsPage(props) {
	const [outputs, setOutputData] = useState([]);
	const getOutputsWithAxios = async () => {
		const data1 = new FormData();
		data1.append("user_id", localStorage.getItem('token'));
		axios.post(getOutputUrl,data1)
		.then((response) => {
			console.log(response);
			const outputs = response.data.outputs;
			setOutputData(outputs);				
		})
		.catch((error) => {
		// error response
		});
	};

	function handleRevert(project_id) {
		const data1 = new FormData();
		data1.append("id", project_id);
		axios.post(getRevertUrl,data1)
		.then((response) => {
			console.log(response);
			getOutputsWithAxios();				
		})
		.catch((error) => {
		// error response
		});
	};
	
	useEffect(() => {
		
		getOutputsWithAxios();
	}, []);	
	
	if(!localStorage.getItem('token')){
		window.location.href = process.env.PUBLIC_URL+"/login";
	}else{
	
	return (
		<div className="dashboard-main">
		<LeftNavbar />
		<div id="main">
            
            
			<div className="page-heading">
				<div className="row">
					<div className="col-12 col-md-9 order-md-1 order-last">
						<h3> Trashed Outputs</h3>						
					</div>
					
				</div>
			</div>
			<div className="page-content">
				<section className="row">
					{ 
					outputs && outputs.map((output,index)=>( 
                        <div className="col-md-3">
                        <div className="home-graph-box box-new p-3 mb-4" style={{height:"150px"}}>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="box-title mt-3 mb-2">Generated</div>
                                </div>
                                <div className="col-md-4 text-right">
                                    
                                    <div className="box-icon" style={{display:"inline"}} onClick={() => handleRevert(output.id)}>
                                        <i className="fa fa-undo" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <p>{output.generated_text.replaceAll("\\n", "").substr(0, 80)} ...</p>
                        </div>
                    </div>
					))}					

					

				</section>
			</div>

            
        </div>
		</div>
	);
	}
}

export default TrashedOutputsPage;