import React, { useState } from "react";
import useForm from "../../useForm";
import validate from '../../FormValidationRules';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import axios from "axios";
import { config } from '../../constant';
const getForgotPasswordUrl = config.url.API_URL+"/users/forgot-password";


function ForgotPasswordPage(props) {
	const [active, setActive] = useState(1);
	const {
		values,
		errors,
		handleChange,
		handleSubmit,
	} = useForm(forgot_password, validate, 'forgot_password',active,changeVal);
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	
	function changeVal(){
	}
	
	function forgot_password() {
		console.log('No errors, submit callback called!');
		console.log(values);
		const dataArray = new FormData();		
		dataArray.append("email", values.email);
		axios.post(getForgotPasswordUrl, dataArray)
		.then((response) => {
			console.log(response);
		// successfully uploaded response
			if(response.data.status === 1){
				
				window.scrollTo(0, 0);
				document.body.scrollTop = 0;
				const successMessage = response.data.msg;
				setSuccessMessage(successMessage);
				const timer = setTimeout(() => { 
				props.history.push('/login'); 
				}, 5000);
				return () => clearTimeout(timer);
			}else{
				window.scrollTo(0, 0);
				document.body.scrollTop = 0;
				const errorMessage = response.data.message;
				setErrorMessage(errorMessage);
				NotificationManager.error('',errorMessage);
			}
		})
		.catch((error) => {
		// error response
		});
	}

	
  /*const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);*/
  
  return (
    <>
      {/* <ExamplesNavbar /> */}
		
		<div id="auth">        
			<div className="row h-100">
				<div className="col-lg-6 align-self-center">
					<div id="auth-right">
						<div className="logo-top text-center log-logo"><a href="/index">Logo</a></div>               
						<div className="log-page-left-text  text-center">
							<h2>Hey there!</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam purus ligula, faucibus nec.</p>

						</div>
					</div>
				</div>


				<div className="col-lg-6 col-12 align-self-center">
					<div id="auth-left">
					   <h3 className="auth-title">Forgot Password</h3> 
						<form className="log-form-area mt-2" method="" onSubmit={handleSubmit}>
							{errorMessage && (
							  <span className="is-danger"> {errorMessage} </span>
							)}
							{successMessage && (
							  <span className="success"> {successMessage} </span>
							)}
							
							<div className="form-group position-relative has-icon-left mb-4">
								<label className="log-input-title">Email address*</label>
								<input type="email" className="form-control form-control-xl" name="email" onChange={handleChange} value={values.email || ''} placeholder="Enter Email" />                    
							</div> 
							
							<button type="submit" className="btn btn-primary btn-block btn-lg mt-5 log-btn-logpage">Reset Password</button>
						</form>
						<div className="text-center mt-4 text-lg fs-4">
							<p className="xtra-accouts">Already have an account?<a href={`${process.env.PUBLIC_URL}/login`} className="font-bold">Login</a>.</p>
							
						</div>
					</div>
				</div>


			</div>

		</div>
		<NotificationContainer/>
    </>
  );
}

export default ForgotPasswordPage;
